<template>
  <h5 class="text-uppercase">Updates</h5>
  <template v-for="update in updates" :key="'update-' + update.version" class="my-3">
    <h6 class="mb-0">Version {{update.version}} - {{update.date}}</h6>
    <hr/>
    <table>
      <tbody>
        <tr v-for="(value, index) in update.changes" :key="'change-' + update.version + '-' + index">
          <td><span :class="'badge mx-3 ' + value.class">{{value.type}}</span></td>
          <td>{{value.text}}</td>
          <td><span v-if="value.requestedBy" class="mx-3">({{value.requestedBy}})</span></td>
        </tr>
      </tbody>
    </table>
  </template>

  <div style="height: 50px;"></div>

</template>

<script>
import axios from 'axios';

export default {
  name: 'changelog',
  data() {
    return {
      version: '0.0.0',
      updates: [
        
      ]
    }
  },
  mounted(){
    this.getUpdates();
  },
  methods: {
    getUpdates() {
      axios.get(`/assets/versions.json`)
      .then(response => {
        this.version = response.data.version;
        this.updates = response.data.updates;
      })
      .catch(error => {
        this.$error("Failed to load updates", error);
      })
    }
  }
}
</script>